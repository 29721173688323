import { Listing } from './Listing';
import { RefundReasons } from './MembershipPlanPurchase';
import { PaymentStatus } from './Payments';

export interface BookingConsumer {
  firstName: string;
  lastName: string;
  email: string;
}

export interface ConsumerPricing {
  subTotal: number; // merchantPrice + feeAmount (inclusive)
  discounts: number;
  taxRates: number;
  total: number;
}

export enum BookingMethod {
  // Book the event and charge the consumer right away
  INSTANT = 'instant',
  // Booking request with the merchant with quote for consumer to accept/refuse.
  // Once the quote is accepted, that's when consumer gets charged
  REQUEST = 'request',
  // Book the delivery order and charge the consumer right away
  DELIVERY = 'delivery',
  ADVERT = 'advert',
}

export interface Booking {
  id: string;
  consumerId: string;
  consumer: BookingConsumer;
  listingSnapshot: Listing;
  paymentStatus: PaymentStatus;
  pricing?: ConsumerPricing;
  nbOfAdditionalGuests: number;

  createdUserId: string;
  createdAt: string;
  updatedUserId?: number;
  updatedAt?: string;
  deletedUserId?: number;
  deletedAt?: string;
}

export interface RefundBookingDto {
  amount: number;
  reason: RefundReasons;
  notes?: string;
}
