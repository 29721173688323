export class MerchantUserRole {
  userId: string; // int in db
  merchantId: string; // int in db
  role: UserRole;
}

export enum UserRole {
  OWNER = 'owner',
  ADMIN = 'admin',
  HOST = 'host',
  SP_INTERNAL = 'sp_internal',
  INSPLB_ADMIN = 'insplb_admin',
}

export class MerchantUser {
  id: number;
  userId: number;
  merchantId: number;
  isDefaultMerchant: boolean;
  status: MerchantUserStatus;
  passwordResetTokenExpiry?: Date;
  user?: User;

  createdUserId?: string;
  createdAt?: Date;
  updatedUserId?: string;
  updatedAt?: Date;
}

export enum MerchantUserStatus {
  PENDING_REGISTRATION = 'pending_registration',
  PENDING_INVITATION = 'pending_invitation',
  ACTIVE = 'active',
  DEACTIVATED = 'deactivated',
  DELETED = 'deleted',
  EXPIRED = 'expired',
}

export enum MerchantUserDisplayStatus {
  'pending_registration' = 'Pending',
  'pending_invitation' = 'Pending',
  active = 'Active',
  deactivated = 'Deactivated',
  deleted = 'Deleted',
  expired = 'Expired',
}

export interface User {
  accessToken?: string;
  bio?: string;
  email: string;
  firstName: string;
  hasAtLeastOneExperience: boolean;
  id: number;
  lastName: string;
  phone: string;
  photoUrl: string;
  primaryMerchantId: string; // int in db
  uid: string;
  roles?: MerchantUserRole[];
  merchantUser?: MerchantUser;
}
